import {configureStore} from '@reduxjs/toolkit';
import accountNotesReducer from './reducers/accountNotesReducer';
import activitiesOverviewReducer from './reducers/activitiesOverviewReducer';
import activitiesReducer from './reducers/activitiesReducer';
import activityDetailReducer from './reducers/activityDetailReducer';
import allAccountsReducer from './reducers/allAccountsReducer';
import applicationDetailReducer from './reducers/applicationDetailReducer';
import communicationsReducer from './reducers/communicationsReducer';
import contactsReducer from './reducers/contactsReducer';
import customerInfoReducer from './reducers/customerInfoReducer';
import draftReducer from './reducers/draftReducer';
import emailReducer from './reducers/emailReducer';
import expectedPaymentsReducer from './reducers/expectedPaymentsReducer';
import fileReducer from './reducers/fileReducer';
import followUpReducer from './reducers/followUpReducer';
import generalReducer from './reducers/generalReducer';
import homeReducer from './reducers/homeReducers';
import invoiceLinesReducer from './reducers/invoiceLinesReducer';
import invoiceReducer from './reducers/invoiceReducer';
import mailboxReducer from './reducers/mailboxReducer';
import paymentsCurrenciesReducer from './reducers/paymentsCurrenciesReducer';
import pendingPaymentDetailsReducer from './reducers/pendingPaymentDetailsReducer';
import pendingPaymentSummaryReducer from './reducers/pendingPaymentSummaryReducer';
import projectedCashReceiptsReducer from './reducers/projectedCashReceiptsReducer';
import reportingGroupLogsReducer from './reducers/reportingGroupLogsReducer';
import scheduledPaymentsReducer from './reducers/scheduledPaymentsReducer';
import statementOfAccountReducer from './reducers/statementOfAccountReducer';
import treeReducer from './reducers/treeReducer';
import userPaymentsReducer from './reducers/userPaymentsReducers';
import usersReducer from './reducers/usersReducer';
import viewSettingsReducer from './reducers/viewSettingsReducer';
import {baseApi} from '../api/baseApi';
import dashboardReducer from '../features/dashboardSlice';

const store = configureStore({
  reducer: {
    generalReducer,
    mailboxReducer,
    communicationsReducer,
    activitiesReducer,
    usersReducer,
    treeReducer,
    statementOfAccountReducer,
    activitiesOverviewReducer,
    accountNotesReducer,
    invoiceLinesReducer,
    applicationDetailReducer,
    activityDetailReducer,
    emailReducer,
    fileReducer,
    invoiceReducer,
    paymentsCurrenciesReducer,
    customerInfoReducer,
    allAccountsReducer,
    userPaymentsReducer,
    // @ts-ignore TODO: To be fixed by adding slice
    scheduledPaymentsReducer,
    // @ts-ignore TODO: To be fixed by adding slice
    pendingPaymentSummaryReducer,
    pendingPaymentDetailsReducer,
    expectedPaymentsReducer,
    followUpReducer,
    homeReducer,
    reportingGroupLogsReducer,
    projectedCashReceiptsReducer,
    draftReducer,
    contactsReducer,
    // @ts-ignore TODO: To be fixed by adding slice
    viewSettingsReducer,
    [baseApi.reducerPath]: baseApi.reducer,
    dashboard: dashboardReducer,
  },
  // @ts-ignore TODO: To be checked later
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // @ts-ignore TODO: To be fixed by adding slice
      serializableCheck: false,
      immutableCheck: false,
    }).concat(baseApi.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});
export type ApplicationState = ReturnType<typeof store.getState>;
export type ApplicationDispatch = typeof store.dispatch;
export default store;
