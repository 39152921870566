import {createTheme} from '@mui/material';

export const theme = createTheme({
  typography: {
    fontFamily: "'Work Sans', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen'",
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#878787',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root.MuiFormLabel-root.MuiInputLabel-outlined.Mui-disabled':
            {
              color: '#878787',
            },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input.Mui-disabled': {
            '-webkit-text-fill-color': '#878787',
            color: '#878787',
            cursor: 'not-allowed',
            backgroundColor: '#f0f0f0',
          },
        },
      },
      defaultProps: {
        sx: {
          fontSize: '13px',
          '& .MuiOutlinedInput-input': {height: 'auto'},
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: '#878787', // Text color when Tab is disabled
            pointerEvents: 'auto', // Disables clicking the Tab
            cursor: 'not-allowed', // Cursor style when Tab is disabled
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: '#878787',
            'pointer-events': 'auto ',
            cursor: 'not-allowed ',
            backgroundColor: '#f0f0f0',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            '-webkit-text-fill-color': '#878787',
            color: '#878787',
            backgroundColor: '#f0f0f0',
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            backgroundColor: '#f0f0f0',
            '-webkit-text-fill-color': '#878787',
            color: '#878787',
          },
        },
      },
      defaultProps: {
        sx: {
          fontSize: '13px',
          '& .MuiOutlinedInput-input': {height: 'auto'},
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#7D4ED8',
    },
    secondary: {
      main: '#BBE9EC',
    },
  },
});
