import {styled} from '@mui/material';
import {constants} from '../../constants/common';
import {isEmpty} from '../../lib/utils';
import {updateViewMessageMailStatusService} from '../../services/header';
import {getUserData} from '../../store/actions/userActions';

export const labelValueForHistoryAndRolledUpHistory = data => {
  if (!isEmpty(data)) {
    return Object.keys(data)
      .filter(key => key.startsWith('ADP'))
      .map(key => {
        const valueKey = key.replace('ADP', 'AvgDaysLate');
        return {label: `${data[key]}:`, value: data[valueKey]};
      });
  }
};

export const labelValueForGivenObjectBasedOnKey = (data, key) => {
  if (!isEmpty(data)) {
    return Object.entries(data)
      .filter(keyValue => {
        return keyValue[0]
          .toLocaleLowerCase()
          .startsWith(key.toLocaleLowerCase());
      })
      .map(filteredData => {
        return {label: `${filteredData[0]}`, value: filteredData[1]};
      });
  }
};

export const findByKeyAndUpdateObj = (obj, key, value) => {
  if (!isEmpty(obj)) {
    const foundKey = Object.keys(obj).find(findKey => findKey === key);
    return {...obj, [foundKey]: value};
  }
};

export const onMessageReadUnread = async (
  {eventKey, isUpdateAll, onRefresh},
  showSnackbar,
) => {
  const {groupKey, userId} = getUserData();
  try {
    await updateViewMessageMailStatusService({
      groupKey,
      userId,
      eventKey,
      isUpdateAll: !!isUpdateAll,
    });
    if (onRefresh) {
      onRefresh();
    }
  } catch {
    showSnackbar(
      constants.FAILED_TO_UPDATE_THE_MAIL_STATUS,
      constants.ERROR,
      'error',
    );
  }
};

export const LockstepCreditScoreValueStyle = styled('label')(() => ({
  color: '#178087',
  fontSize: 'medium',
  fontWeight: '600',
  marginRight: '5px',
  cursor: 'pointer',
}));
export const LockstepCreditScoreLabelStyle = styled('label')(() => ({
  fontSize: 'small',
  fontWeight: '500',
  marginRight: '5px',
}));
export const DoNotShareWithCreditBureauStyle = styled('label')(() => ({
  fontSize: 'small',
  fontWeight: '500',
  marginTop: '.5rem',
  marginBottom: '1rem',
}));

export const StyledTableDiv = styled('div')(() => ({
  maxWidth: '440px',
  width: '100%',
}));

export const StyledFormDiv = styled('div')(() => ({
  minWidth: '440px',
  marginLeft: '18px',
}));

export const StyledFormDivColumns = styled('div')(() => ({
  minWidth: '220px',
  maxWidth: '220px',
  flexGrow: 2,
  marginRight: '5px',
}));

// TODO: Need to remove this function
export const calculateColumnWidth = field => {
  const query = `[data-field="${field}"].MuiDataGrid-cell`;
  const cellElements = document.querySelectorAll(query);
  let minWidth = 0;
  cellElements.forEach(cellElement => {
    if (
      cellElement?.firstChild?.nodeName
        ?.toLowerCase()
        ?.includes(constants.TEXT.toLowerCase())
    ) {
      minWidth = Math.max(minWidth, cellElement.scrollWidth);
    } else {
      const cellContentWidth = cellElement.firstChild?.scrollWidth || 80;
      minWidth = Math.max(minWidth, cellContentWidth);
    }
  });
  return minWidth + 25;
};

export const contactsTabCheckboxesObject = obj => [
  {
    key: 'includeInEmailYN',
    label: constants.INCLUDE_IN_EMAIL,
    checked: obj?.includeInEmailYN === 1,
    onChange: obj?.onChange,
    type: 'number',
  },
  {
    key: 'includeInEmailYN2',
    label: `${constants.INCLUDE_IN_EMAIL}2`,
    checked: obj?.includeInEmailYN2 === 1,
    onChange: obj?.onChange,
    type: 'number',
  },
  {
    key: 'includeInTextYN',
    label: constants.INCLUDE_IN_TEXT,
    checked: obj?.includeInTextYN,
    onChange: obj?.onChange,
  },
  {
    key: 'includeInVoiceYN',
    label: constants.INCLUDE_IN_VOICE,
    checked: obj?.includeInVoiceYN,
    onChange: obj?.onChange,
  },
  {
    key: 'primaryContactFlag',
    label: constants.PRIMARY,
    checked: obj?.primaryContactFlag === 1,
    onChange: obj?.onChange,
    type: 'number',
  },
  {
    key: 'keepCntctInSync',
    label: constants.KEEP_IN_SYNC_WITH_ERP,
    checked: obj?.keepCntctInSync === 1,
    onChange: obj?.onChange,
    type: 'number',
  },
  {
    key: 'statementContactFlag',
    label: constants.STATEMENT,
    checked: obj?.statementContactFlag === 1,
    onChange: obj?.onChange,
    type: 'number',
  },
  {
    key: 'invoiceContactFlag',
    label: constants.INVOICE,
    checked: obj?.invoiceContactFlag === 1,
    onChange: obj?.onChange,
    type: 'number',
  },
];
