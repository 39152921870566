import {constants} from '../../constants/common';
import {
  addOrRemoveFromFavorites,
  exportMessagesService,
  fetchJWTToken,
  fetchMessagesService,
} from '../../services/header';
import {downloadExcel} from '../../utils/fileUtils';
import {actionTypes} from '../constants/actionTypes';
import {updateIsEmailTemplateActivities} from './activitiesOverviewActions';

export const showDialog = ({
  width,
  title,
  dialogContent,
  className,
  headerIcon,
  position,
}) => {
  return {
    type: actionTypes.SHOW_DIALOG,
    width,
    title,
    dialogContent,
    className,
    headerIcon,
    position,
  };
};

export const hideDialog = () => (dispatch, getState) => {
  const {isEmailTemplateActivities} = getState().activitiesOverviewReducer;
  if (isEmailTemplateActivities) {
    dispatch(updateIsEmailTemplateActivities(false));
  }
  dispatch({
    type: actionTypes.HIDE_DIALOG,
  });
};

export const setCurrentRoute = route => {
  return {
    type: actionTypes.SETTING_CURRENT_ROUTE,
    route,
  };
};

export const updatePageRowCount = pageRowCount => {
  return {
    type: actionTypes.UPDATE_PAGE_ROW_COUNT,
    pageRowCount,
  };
};

export const updateLoadedPage = loadedPage => {
  return {
    type: actionTypes.SET_LOADED_PAGE,
    loadedPage,
  };
};

export const setPageInputTooltip = pageInputTooltip => {
  return {
    type: actionTypes.SET_PAGE_INPUT_TOOLTIP,
    pageInputTooltip,
  };
};

export const setCurrentPage = paginationCurrentPage => {
  return {
    type: actionTypes.SET_CURRENT_PAGE,
    paginationCurrentPage,
  };
};

export const setBestFit = () => {
  return {
    type: actionTypes.SET_BEST_FIT,
  };
};

export const setSelectedRows = rows => {
  return {
    type: actionTypes.SET_SELECTED_ROWS,
    rows,
  };
};

export const updateTableFilterDisplayType = tableFilterDisplayType => {
  return {
    type: actionTypes.SET_TABLE_FILTER_DISPLAY_TYPE,
    tableFilterDisplayType,
  };
};

export const setFilteredData = filteredData => {
  return {
    type: actionTypes.SET_FILTERED_DATA,
    filteredData,
  };
};

export const updateEnableEnhancedAdmin = enableEnhancedAdmin => {
  return {
    type: actionTypes.ENABLE_ENHANCED_ADMIN,
    enableEnhancedAdmin,
  };
};

export const updateSideNavRefresh =
  (sideNavRefresh = true) =>
  dispatch => {
    dispatch({
      type: actionTypes.REFRESH_SIDE_NAV,
      sideNavRefresh,
    });
  };

export const updateSelectedSideNavElement =
  selectedSideNavElement => dispatch => {
    dispatch({
      type: actionTypes.UPDATE_SELECTED_SIDENAV_ELEMENT,
      selectedSideNavElement,
    });
  };

export const handleAddOrRemoveFromFavorites =
  ({userId, payload = {}}, showSnackbar) =>
  (dispatch, getState) => {
    const {selectedSideNavElement} = getState().generalReducer;
    dispatch({type: actionTypes.ADDING_TO_FAVORITE});
    return addOrRemoveFromFavorites({
      userId,
      payload: selectedSideNavElement || payload,
    })
      .then(() => {
        dispatch({type: actionTypes.ADDED_TO_FAVORITE});
        showSnackbar(constants.ADDED_TO_FAVORITE, constants.SUCCESS, 'success');
        dispatch(updateSideNavRefresh());
      })
      .catch(() => {
        dispatch({type: actionTypes.ADDING_TO_FAVORITE_FAILED});
        showSnackbar(
          constants.ADDING_TO_FAVORITE_FAILED,
          constants.ERROR,
          'error',
        );
      });
  };

export const updateSideBarMenuItems = sidebarMenu => dispatch => {
  dispatch({type: actionTypes.FETCHED_SIDEBAR_MENU, sidebarMenu});
};

export const updateSelectedCustomer = selectedCustomer => dispatch => {
  const customerWithLabelAndValue = {...selectedCustomer};
  customerWithLabelAndValue.label = `${
    customerWithLabelAndValue.custID || customerWithLabelAndValue.custId
  }: ${customerWithLabelAndValue.custName}`;
  customerWithLabelAndValue.value = customerWithLabelAndValue.custKey;
  dispatch({
    type: actionTypes.UPDATE_SELECTED_CUSTOMER,
    selectedCustomer: customerWithLabelAndValue,
  });
};

export const updateShowCustomerInfo = showCustomerInfo => dispatch => {
  dispatch({type: actionTypes.SHOW_CUSTOMER_INFO_CONTAINER, showCustomerInfo});
};

export const fetchHeaderViewMessages =
  (payload = {}) =>
  dispatch => {
    dispatch({type: actionTypes.FETCHING_MESSAGES});
    fetchMessagesService(payload)
      .then(({data}) => {
        dispatch({
          type: actionTypes.FETCHED_MESSAGES,
          messages: data.value,
          count: data.count,
        });
      })
      .catch(() => {
        dispatch({type: actionTypes.FETCHING_MESSAGES_FAILED});
      });
  };

export const exportHeaderViewMessages =
  (payload, showSnackbar) => (dispatch, getState) => {
    const {pageSize, skip} = getState().generalReducer.viewMessages;
    const updatedPayload = {...payload, pageSize, skip};
    dispatch({type: actionTypes.EXPORTING_MESSAGES});
    showSnackbar('Exporting view messages to excel', constants.INFO, 'info');
    exportMessagesService(updatedPayload)
      .then(res => {
        downloadExcel(
          res.data,
          payload.mode ?? 'ExportFile',
          showSnackbar,
          constants.MESSAGES_EXPORTED_TO_EXCEL,
        );
        dispatch({
          type: actionTypes.EXPORTED_MESSAGES,
        });
      })
      .catch(() => {
        showSnackbar(
          'Failed to export view messages',
          constants.ERROR,
          'error',
        );
        dispatch({type: actionTypes.EXPORTING_MESSAGES_FAILED});
      });
  };

export const updateViewMessagesSkip = skip => dispatch => {
  dispatch({type: actionTypes.UPDATE_VIEW_MESSAGES_SKIP, skip});
};

export const updateViewMessagesPageSize = pageSize => dispatch => {
  dispatch({type: actionTypes.UPDATE_VIEW_MESSAGES_PAGE_SIZE, pageSize});
};

export const getJwtToken = () => dispatch => {
  dispatch({type: actionTypes.FETCHING_JWT_TOKENS});
  return fetchJWTToken()
    .then(({data}) => {
      dispatch({
        type: actionTypes.FETCHED_JWT_TOKENS,
        jwtTokenData: data,
      });
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_JWT_TOKENS_FAILED});
    });
};
