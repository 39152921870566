import {GridColDef} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {Tooltip} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {CompanyExchangeRate} from '../../../../api/admin/exchangeRate/exchangeRateApi';

interface Column extends Omit<GridColDef, 'field'> {
  field: keyof CompanyExchangeRate | 'actions';
}

const ActionsCell: React.FC<{row: CompanyExchangeRate}> = ({row}) => {
  const navigate = useNavigate();

  return (
    <div>
      <Tooltip title="Edit">
        <EditIcon
          style={{cursor: 'pointer', marginRight: '10px'}}
          onClick={() =>
            navigate(`edit/${row.baseCurrID}`, {
              state: {companyID: row.companyID},
            })
          }
        />
      </Tooltip>
      <Tooltip title="Delete">
        <DeleteIcon
          style={{cursor: 'pointer'}}
          onClick={() =>
            navigate(`delete/${row.baseCurrID}`, {
              state: {companyID: row.companyID},
            })
          }
        />
      </Tooltip>
    </div>
  );
};

export const ExchangeRateColumns: Column[] = [
  {
    field: 'actions',
    headerName: 'Actions',
    minWidth: 100,
    sortable: false,
    filterable: false,
    renderHeaderFilter: () => null,
    renderCell: (cellValues: {row: CompanyExchangeRate}) => (
      <ActionsCell row={cellValues.row} />
    ),
  },

  {
    field: 'baseCurrID',
    headerName: 'BaseCurrID',
    minWidth: 150,
    type: 'string',
    sortable: true,
    flex: 1,
  },
  {
    field: 'currExchRate',
    headerName: 'Curr Exch Rate',
    minWidth: 250,
    type: 'string',
    sortable: true,
    flex: 1,
  },
];
