import {Button} from '@mui/material';
import {
  GridAddIcon,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import CachedIcon from '@mui/icons-material/Cached';
import {FaArrowsAltH} from 'react-icons/fa';
import {Box} from '../../../../ui/layouts';

export default function ExchangeRateToolbar({
  onRefresh,
  onAddNewRecord,
  applyBestFit,
}: {
  onRefresh: () => void;
  applyBestFit: () => void;
  onAddNewRecord: () => void;
}) {
  return (
    <Box display="flex" justifyContent="space-between">
      <GridToolbarContainer>
        <Button startIcon={<CachedIcon />} onClick={onRefresh}>
          Refresh
        </Button>
        <Button startIcon={<FaArrowsAltH />} onClick={applyBestFit}>
          BestFit
        </Button>
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
      <Button startIcon={<GridAddIcon />} onClick={onAddNewRecord}>
        Add new record
      </Button>
    </Box>
  );
}
