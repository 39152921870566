import {ExchangeRateFormSchemaType} from '../../../components/admin/master-files/exchange-rate/schemas/ExchangeRateFormSchema';
import {baseApi} from '../../baseApi';

export interface CompanyExchangeRate {
  groupKey: string;
  companyID: string;
  baseCurrID: string;
  currExchRate: number;
  insertDate: string;
  updateDate: string;
  uniqueKey: string;
  id: string;
  tableName: string;
  idName: string;
}

export const exchangeRateApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getCompanyExchangeRateInfo: build.query<
      CompanyExchangeRate[],
      {groupKey: string; companyID: string}
    >({
      query: ({groupKey, companyID}) =>
        `/v4/api/admin/exchangeRate/for/${groupKey}/${companyID}/exchangesRates`,
      providesTags: result => {
        if (!result) {
          return ['ExchangeRate'];
        }
        const tags = result.map(({companyID}) => ({
          type: 'ExchangeRate' as const,
          id: companyID,
        }));
        return [...tags, 'ExchangeRate'];
      },
    }),
    getCompanyExchangeRateInfoById: build.query<
      ExchangeRateFormSchemaType,
      {groupKey: string; companyID: string; baseCurrID: string}
    >({
      query: ({groupKey, companyID, baseCurrID}) =>
        `/v4/api/admin/exchangeRate/for/${groupKey}/${companyID}/${baseCurrID}`,
      keepUnusedDataFor: 0,
    }),
    createExchangeRate: build.mutation<void, ExchangeRateFormSchemaType>({
      query: body => ({
        url: '/v4/api/admin/exchangeRate',
        method: 'POST',
        body,
      }),
    }),
    updateExchangeRate: build.mutation<void, ExchangeRateFormSchemaType>({
      query: body => ({
        url: '/v4/api/admin/exchangeRate',
        method: 'Put',
        body,
      }),
    }),
    deleteExchangeRate: build.mutation<
      void,
      {groupKey: string; companyID: string; baseCurrID: string}
    >({
      query: body => ({
        url: `/v4/api/admin/exchangeRate`,
        method: 'Delete',
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        if (error) {
          return [];
        }
        return [{type: 'ExchangeRate', id: arg.baseCurrID}];
      },
    }),
  }),
});

export const {
  useGetCompanyExchangeRateInfoQuery,
  useGetCompanyExchangeRateInfoByIdQuery,
  useCreateExchangeRateMutation,
  useUpdateExchangeRateMutation,
  useDeleteExchangeRateMutation,
} = exchangeRateApi;
