import {constants} from '../constants/common';
import {getConfig} from '../lib/utils';

export const MODE_TYPES = {
  new: 0,
  enquiry: 1,
  activityOnEnquiry: 2,
};

export const REPLY_TYPE = {
  reply: 1,
  replyAll: 2,
  forward: 3,
  other: 0,
};

export const DELETED_MAIL_STATUS = -1;

export const MAIL_STATUS = {
  unread: 2,
  read: 3,
  queued: -2,
  error: -1,
};

export const ISSUE_STATUS = {
  complete: 1,
  inComplete: 0,
};

export const TRAN_TYPE = {
  CR: 'CR',
  IN: 'IN',
  CM: 'CM',
  RV: 'RV',
  PP: 'PP',
};

export const MAX_EXCEL_DOWNLOAD = 5000;

export const SURCHARGE_STATUS_CODE = {
  SUCCESS: 0,
  NO_INVOICE_TO_GET_SURCHARGE_FEE: 1,
  FAILED: 2,
  NOT_SUPPORTED_FOR_ACTIVE_MERCHANT: 3,
  NO_PAYMENT_METHOD_AVAILABLE: 4,
  ONE_OR_MORE_NEGATIVE_TRANSACTION: 5,
  Warning: 6,
};

export const DEFAULT_PAYMENT_METHOD = 1;

export const PAYMENT_ERROR_CODES = {
  CARD_DECLINED: '5',
  OTHER: '-1',
};

export const CALL_STATUS = {
  QUEUED: 0,
  SUCCESSFUL: 99,
};

export const filterBuilderOperators = [
  {name: 'EqualTo', label: 'EqualTo'},
  {name: 'NotEqualTo', label: 'NotEqualTo'},
  {name: 'LessThan', label: 'LessThan'},
  {name: 'GreaterThan', label: 'GreaterThan'},
  {name: 'LessThanOrEqualTo', label: 'LessThanOrEqualTo'},
  {name: 'GreaterThanOrEqualTo', label: 'GreaterThanOrEqualTo'},
  {name: 'contains', label: 'contains'},
  {name: 'beginsWith', label: 'begins with'},
  {name: 'endsWith', label: 'ends with'},
  {name: 'doesNotContain', label: 'does not contain'},
  {name: 'doesNotBeginWith', label: 'does not begin with'},
  {name: 'doesNotEndWith', label: 'does not end with'},
  {name: 'null', label: 'is null'},
  {name: 'notNull', label: 'is not null'},
  {name: 'in', label: 'in'},
  {name: 'notIn', label: 'not in'},
  {name: 'between', label: 'between'},
  {name: 'notBetween', label: 'not between'},
];

export const creditScoreBgColor = {
  VERY_POOR: '#FF0000',
  POOR_OR_FAIRISH: '#FFC000',
  GOOD: '#92D050',
  VERY_GOOD: '#00B050',
};
// TODO: Need to refactor this function
// eslint-disable-next-line consistent-return
export const lockstepCreditScoreBgColorWithLabel = (creditScore: any) => {
  if (creditScore > 0 && creditScore < 20) {
    return {color: creditScoreBgColor.VERY_POOR, label: constants.VERY_POOR};
  }

  if (creditScore > 20 && creditScore < 40) {
    return {
      color: creditScoreBgColor.POOR_OR_FAIRISH,
      label: constants.POOR,
    };
  }

  if (creditScore > 40 && creditScore < 50) {
    return {
      color: creditScoreBgColor.POOR_OR_FAIRISH,
      label: constants.FAIRISH,
    };
  }

  if (creditScore > 60 && creditScore < 90) {
    return {
      color: creditScoreBgColor.GOOD,
      label: constants.GOOD,
    };
  }

  if (creditScore > 89 && creditScore <= 100) {
    return {
      color: creditScoreBgColor.VERY_GOOD,
      label: constants.VERY_GOOD,
    };
  }
};

export const SESSION_NACM_SUPPORT = {
  NACM: '1',
  CREDIT_SAFE: '2',
};

export const defaultViewMessageMode = 'viewMessages';

export const ONLINE_CM_APPLICATION_TYPE = {
  ALL: 2,
  CREDIT_USER: 1,
  NONE: 0,
};

export const REPORT_TYPE = {
  COMMUNICATION: 'Communication',
  INVOICE: 'Invoice',
  ACCOUNT: 'Account',
};

export const REPORT_OUTPUT_TYPE = {
  PREVIEW: 0,
  Print: 1,
  Email: 2,
  AutoEmail: 3,
};

export const PAGE_SIZE = 20;

export const UDF_LABELS_REF = {
  invoiceUdf: 'udfLabelInvc',
  userPaymentsUdf: 'udfLabelCPmt',
  userContactsUdf: 'udfLabelCntct',
  invoiceLinesUdf: 'udfLabelILine',
  customerUdf: 'udfLabelCust',
  disputeInvoiceUdf: 'udfLabelDispInvc',
} as const;

export const loginReturnUrl = `/connect/authorize?client_id=appid&redirect_uri=${
  getConfig().apiEndpoint
}/swagger/&response_type=code+id_token&nonce=8d2d9e66-2344-47b0-8784-409e34533596&scope=openid+profile+api+offline_access`;

export enum TimeFrame {
  Today = 0,
  ThisWeek = 1,
  ThisMonth = 2,
  ThisYear = 3,
  DateRange = 4,
}

export const PaymentOptions = [
  {label: 'Not Allowed', value: 0},
  {label: 'Allowed', value: 1},
  {label: 'Not Allowed with App Detail', value: 2},
];

export const PaymentMatchOptions = [
  {label: 'Match TranID, TranAmount, TranDate', value: 0},
  {label: 'Match TranID, TranAmount', value: 1},
  {label: 'Match TranAmount, TranDate', value: 2},
  {label: 'Match PmtRef, TranAmount, TranDate', value: 3},
  {label: 'Match PmtRef TranAmount', value: 4},
  {label: 'Match PmtRef, TranDate', value: 5},
];

export const CustomerOnlinePaymentOptions = [
  {label: 'None', value: 0},
  {label: 'Credit Card/EFT', value: 1},
  {label: 'EFT Only', value: 2},
  {label: 'Credit Card Only', value: 3},
];

export const SchedulePaymentOptions = [
  {label: 'Disabled', value: 0},
  {label: 'Enabled - Approval Required', value: 1},
  {label: 'Enabled - No Approval Required', value: 2},
];

export const CustomerIdentifiers = [
  {label: 'Cust ID', value: 0},
  {label: 'Cust Key', value: 1},
];

export const CreditMemosOptions = [
  {label: 'None', value: 0},
  {label: 'Credit User', value: 1},
  {label: 'All', value: 2},
];

export const DualMerchAcctTypes = [
  {label: 'Credit Card', value: 0},
  {label: 'EFT', value: 1},
  {label: 'None', value: 99},
];

export const PaymentTypes = [
  {label: 'None', value: 0},
  {label: 'CC', value: 1},
  {label: 'EFT', value: 2},
];

export const WebhookTypes = [{label: 'Payment', value: 0}];

export const reportsMapping = [
  {label: constants.PDF, value: 0},
  {label: constants.TEXT, value: 3},
  {label: constants.CSV, value: 4},
  {label: constants.XLS, value: 5},
  {label: constants.XLSX, value: 6},
  {label: constants.RTF, value: 7},
  {label: constants.BMP, value: 8},
  {label: constants.GIF, value: 9},
  {label: constants.JPEG, value: 10},
  {label: constants.PNG, value: 11},
  {label: constants.TIFF, value: 12},
] as const;

export enum CredentialType {
  FileLocation = 0,
  AltecDocLink = 1,
  FTP = 2,
  RackSpace = 3,
  SFTP = 4,
  QBSQL = 5,
  Intacct = 6,
  NetSuite = 7,
  Tropo = 8,
  Security = 9,
  SQLDocAccess = 10,
  ManualATCUpload = 11,
  PrintFromATC = 12,
  CreditSafe = 13,
  DynamicsBC = 14,
  EmailATCBlob = 15,
  Xero = 16,
  ChargeBee = 17,
  Salesforce = 18,
  QBO = 19,
  Acumatica = 20,
  PremiseWebhook = 21,
  EpicorP21 = 22,
  WinTeam = 23,
  APIDocuments = 24,
  Digicel = 25,
  Eclipse = 26,
  None = 27,
  Gmail = 28,
  Office365 = 29,
  EpicorKinetic = 30,
  APIConnector = 31,
}

export const getTabNameFromActivityType = (activityType: number) => {
  switch (activityType) {
    case 1:
    case 2:
      return constants.EMAIL_TAB;
    case 3:
    case 6:
    case 7:
      return constants.NOTES_TAB;
    case 4:
      return constants.PAYMENTS_TAB;
    case 5:
      return constants.TRANSACTIONS_TAB;
    default:
      return constants.NOTES_TAB;
  }
};
