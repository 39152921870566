import {GridColDef} from '@mui/x-data-grid';
import {useState} from 'react';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import {constants} from '../../../../constants/common';
import {useSnackbar} from '../../../../context/SnackbarContext';
import {useSessionStorage} from '../../../../hooks/useSessionStorage';
import {DataGrid} from '../../../../ui/data';
import {Typography} from '../../../../ui/displays';
import {Box} from '../../../../ui/layouts';
import {PAGE_SIZE} from '../../../../utils/constants';
import {ExchangeRateColumns} from './columns';
import {calculateColumnWidth} from '../../../header/helper';
import ExchangeRateToolbar from './ExchangeRateToolbar';
import {useGetCompanyExchangeRateInfoQuery} from '../../../../api/admin/exchangeRate/exchangeRateApi';

function ExchangeRate() {
  const [{groupKey}] = useSessionStorage('profile');
  const {companyID} = useParams<{companyID: string | undefined}>();
  const showSnackbar = useSnackbar();
  const navigate = useNavigate();
  const [bestFit, setBestFit] = useState<boolean>(false);
  const [bestFitColumns, setBestFitColumns] = useState<GridColDef[]>([]);
  const {data, isLoading, isFetching, error, refetch} =
    useGetCompanyExchangeRateInfoQuery({
      groupKey,
      companyID: companyID || '',
    });

  if (error) {
    showSnackbar('Failed to fetch Exchange Rates', constants.ERROR, 'error');
  }

  const applyBestFit = () => {
    const bestFitColumnsTemp = ExchangeRateColumns.map(column => {
      const minWidth = calculateColumnWidth(column.field);
      return {
        ...column,
        minWidth,
      };
    });
    setBestFit(!bestFit);
    setBestFitColumns(bestFitColumnsTemp);
  };

  const onRefresh = () => {
    refetch();
  };

  const onAddNewRecord = () => {
    navigate('add', {
      state: {companyID},
    });
  };

  return (
    <>
      <Box>
        <Typography variant="h5" textTransform="uppercase" marginY="16px">
          Exchange Rates
        </Typography>
        <DataGrid
          loading={isLoading || isFetching}
          CustomToolbar={ExchangeRateToolbar}
          columns={bestFit ? bestFitColumns : ExchangeRateColumns}
          rows={data || []}
          sortingMode="client"
          disableColumnMenu
          paginationMode="client"
          pageSize={PAGE_SIZE}
          headerFilters
          filterMode="client"
          getRowId={row => row.uniqueKey}
          customToolbarMethods={{
            onRefresh,
            applyBestFit,
            onAddNewRecord,
          }}
          initialState={{
            pinnedColumns: {left: ['actions']},
          }}
        />
      </Box>
      <Outlet />
    </>
  );
}

export default ExchangeRate;
