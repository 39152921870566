import {lazy} from 'react';
import withSuspense from './withSuspense';

export const Logout = withSuspense(
  lazy(() =>
    import('./components/auth/logout').then(module => ({
      default: module.default,
    })),
  ),
);
export const Layout = withSuspense(
  lazy(() =>
    import('./Layout').then(module => ({
      default: module.default,
    })),
  ),
);
export const CommunicateWrapper = withSuspense(
  lazy(() =>
    import('./components/communicate/CommunicateWrapper').then(module => ({
      default: module.default,
    })),
  ),
);
export const Home = withSuspense(
  lazy(() =>
    import('./components/home/Home').then(module => ({
      default: module.default,
    })),
  ),
);
export const ErrorBoundaryWrapper = withSuspense(
  lazy(() =>
    import('./components/error-boundary/ErrorBoundaryWrapper').then(module => ({
      default: module.default,
    })),
  ),
);
export const Communication = withSuspense(
  lazy(() =>
    import('./components/messages/communication/Communication').then(
      module => ({default: module.default}),
    ),
  ),
);
export const EditPaymentMethods = withSuspense(
  lazy(() =>
    import('./components/header/EditPaymentMethods').then(module => ({
      default: module.default,
    })),
  ),
);
export const MailBox = withSuspense(
  lazy(() =>
    import('./components/messages/mail-box/MailBox').then(module => ({
      default: module.default,
    })),
  ),
);
export const Accounts = withSuspense(
  lazy(() =>
    import('./components/accounts/Accounts').then(module => ({
      default: module.default,
    })),
  ),
);

export const Login = withSuspense(
  lazy(() =>
    import('./components/auth/login/Login').then(module => ({
      default: module.Login,
    })),
  ),
);
export const FavoriteAccountsHandler = withSuspense(
  lazy(() =>
    import('./components/accounts/FavoriteAccountsHandler').then(module => ({
      default: module.default,
    })),
  ),
);
export const ExpectedPayments = withSuspense(
  lazy(() =>
    import(
      './components/inquiries/by-user/ExpectedPayments/ExpectedPayments'
    ).then(module => ({default: module.ExpectedPayments})),
  ),
);
export const FollowUp = withSuspense(
  lazy(() =>
    import('./components/messages/follow-up/FollowUp').then(module => ({
      default: module.FollowUp,
    })),
  ),
);
export const PendingPayments = withSuspense(
  lazy(() =>
    import(
      './components/inquiries/by-user/pending-payments/PendingPayments'
    ).then(module => ({default: module.PendingPayments})),
  ),
);
export const DraftDashboard = withSuspense(
  lazy(() =>
    import('./components/messages/draft-dashboard/DraftDashboard').then(
      module => ({default: module.DraftDashboard}),
    ),
  ),
);
export const Dashboard = withSuspense(
  lazy(() =>
    import('./components/dashboard/Dashboard').then(module => ({
      default: module.default,
    })),
  ),
);
export const Activities = withSuspense(
  lazy(() =>
    import('./components/activities/Activities').then(module => ({
      default: module.default,
    })),
  ),
);
export const HandleInquiriesByUserChildRoutes = withSuspense(
  lazy(() =>
    import('./routes/inquiries/by-user/HandleInquiriesByUserChildRoutes').then(
      module => ({
        default: module.HandleInquiriesByUserChildRoutes,
      }),
    ),
  ),
);
export const AccountOverview = withSuspense(
  lazy(() =>
    import('./components/account-overview/AccountOverview').then(module => ({
      default: module.default,
    })),
  ),
);
export const PageNotFound = withSuspense(
  lazy(() =>
    import('./components/common/PageNotFound').then(module => ({
      default: module.default,
    })),
  ),
);
export const Unauthorized = withSuspense(
  lazy(() =>
    import('./components/auth/Unauthorized').then(module => ({
      default: module.default,
    })),
  ),
);

/** Admin pages */
export const ActivityTemplate = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/activity-template/ActivityTemplate'
    ).then(module => ({
      default: module.default,
    })),
  ),
);
export const ActivityTemplateDelete = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/activity-template/ActivityTemplateDelete'
    ).then(module => ({
      default: module.default,
    })),
  ),
);
export const ActivityTemplateFormContainer = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/activity-template/ActivityTemplateFormContainer'
    ).then(module => ({
      default: module.default,
    })),
  ),
);

export const MaintainReportDialog = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/activity-template/MaintainReportDialog'
    ).then(module => ({
      default: module.default,
    })),
  ),
);

export const ReasonCode = withSuspense(
  lazy(() =>
    import('./components/admin/master-files/dispute-code/ReasonCode').then(
      module => ({
        default: module.default,
      }),
    ),
  ),
);

export const ReasonCodeDelete = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/dispute-code/ReasonCodeDelete'
    ).then(module => ({
      default: module.default,
    })),
  ),
);

export const ReasonCodeFormContainer = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/dispute-code/ReasonCodeFormContainer'
    ).then(module => ({
      default: module.default,
    })),
  ),
);

export const SalesRep = withSuspense(
  lazy(() =>
    import('./components/admin/master-files/sales-rep/SalesRep').then(
      module => ({
        default: module.default,
      }),
    ),
  ),
);

export const ReportingGroups = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/reporting-groups/ReportingGroups'
    ).then(module => ({
      default: module.default,
    })),
  ),
);

export const ReportingGroupsFormContainer = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/reporting-groups/ReportingGroupsFormContainer'
    ).then(module => ({
      default: module.default,
    })),
  ),
);

export const Report = withSuspense(
  lazy(() =>
    import('./components/admin/master-files/report/Report').then(module => ({
      default: module.default,
    })),
  ),
);

export const ReportFormContainer = withSuspense(
  lazy(() =>
    import('./components/admin/master-files/report/ReportFormContainer').then(
      module => ({
        default: module.default,
      }),
    ),
  ),
);

export const ReportDelete = withSuspense(
  lazy(() =>
    import('./components/admin/master-files/report/ReportDelete').then(
      module => ({
        default: module.default,
      }),
    ),
  ),
);

export const MessageSettings = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/message-settings/MessageSettings'
    ).then(module => ({
      default: module.default,
    })),
  ),
);

export const MessageSettingsFormContainer = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/message-settings/MessageSettingsFormContainer'
    ).then(module => ({
      default: module.default,
    })),
  ),
);

export const Company = withSuspense(
  lazy(() =>
    import('./components/admin/master-files/company/Company').then(module => ({
      default: module.default,
    })),
  ),
);

export const ActivityTypes = withSuspense(
  lazy(() =>
    import('./components/admin/master-files/activity-types/ActivityTypes').then(
      module => ({
        default: module.default,
      }),
    ),
  ),
);
export const ActivityTypesDelete = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/activity-types/ActivityTypesDelete'
    ).then(module => ({
      default: module.default,
    })),
  ),
);
export const ActivityTypesFormContainer = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/activity-types/ActivityTypesFormContainer'
    ).then(module => ({
      default: module.default,
    })),
  ),
);

export const CommunicationTemplate = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/communication-template/CommunicationTemplate'
    ).then(module => ({
      default: module.default,
    })),
  ),
);

export const CommunicationTemplateDelete = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/communication-template/CommunicationTemplateDelete'
    ).then(module => ({
      default: module.default,
    })),
  ),
);

export const CommunicationTemplateFormContainer = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/communication-template/CommunicationTemplateFormContainer'
    ).then(module => ({
      default: module.default,
    })),
  ),
);

export const CompanyInfoFormContainer = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/company/company-info/CompanyInfoFormContainer'
    ).then(module => ({
      default: module.default,
    })),
  ),
);

export const CompanyWebhookFormContainer = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/company/company-webhooks/CompanyWebhookFormContainer'
    ).then(module => ({
      default: module.default,
    })),
  ),
);

export const CompanyWebhookDelete = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/company/company-webhooks/CompanyWebhookDelete'
    ).then(module => ({
      default: module.default,
    })),
  ),
);

export const CreditClass = withSuspense(
  lazy(() =>
    import('./components/admin/master-files/credit-class/CreditClass').then(
      module => ({
        default: module.default,
      }),
    ),
  ),
);

export const CreditClassRuleContainer = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/credit-class/CreditClassRuleContainer'
    ).then(module => ({
      default: module.default,
    })),
  ),
);

export const ActivityTemplateDialog = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/credit-class/ActivityTemplateDialog'
    ).then(module => ({
      default: module.default,
    })),
  ),
);

export const ExchangeRateDelete = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/exchange-rate/ExchangeRateDelete'
    ).then(module => ({
      default: module.default,
    })),
  ),
);

export const ExchangeRateFormContainer = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/exchange-rate/ExchangeRateFormContainer'
    ).then(module => ({
      default: module.default,
    })),
  ),
);

export const AccessCredentialDelete = withSuspense(
  lazy(() =>
    import(
      './components/admin/master-files/access-credential/AccessCredentialDelete'
    ).then(module => ({
      default: module.default,
    })),
  ),
);
