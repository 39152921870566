import {useNavigate} from 'react-router-dom';
import {Dialog} from '../../../../../ui';
import ExchangeRate from '../../exchange-rate/ExchangeRate';

const ManageExchangeRateDialog = () => {
  const navigate = useNavigate();
  return (
    <Dialog
      open
      title="Currency Exchange Rates"
      onClose={() => navigate(-1)}
      fullScreen
    >
      <ExchangeRate />
    </Dialog>
  );
};

export default ManageExchangeRateDialog;
